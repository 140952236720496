import React, { useState, useCallback } from "react";
import styles from './App.module.css';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import photos  from './photos';
import ReactWeather, { useOpenWeather } from 'react-open-weather';

function App() {
  const scroll = id => {
    const element = document.getElementById(id);
     window.scrollTo({
       top: element.offsetTop - 70,
       behavior: 'smooth'
     });
  }
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  const { data, isLoading, errorMessage } = useOpenWeather({
    key: '2e0abbb2bd77cce15c6b9885ad930b54',
    lat: '34.200910',
    lon: '-77.800660',
    unit: 'imperial',
  });
  return (
    <div id='app' className={styles.app}>

      <div className={styles.headerRow}>
        <div className={styles.navHomeContainer} onClick={() => scroll('app')}>
          <img alt='' className={styles.navHome} src={`${process.env.PUBLIC_URL}/fish.png`} />
        </div>
        <div className={styles.navLinks}>
          <div
            className={styles.navLink}
            onClick={() => scroll('events')}
          >
            Events
          </div>
          <div
            className={styles.navLink}
            onClick={() => scroll('lodging')}
          >
            Lodging
          </div>
          <div
            className={styles.navLink}
            onClick={() => scroll('travel')}
          >
            Travel
          </div>
          <div
            className={styles.navLink}
            onClick={() => scroll('registry')}
          >
            Registry
          </div>
          <div
            className={styles.navLink}
            // onClick={() => window.open('https://photos.app.goo.gl/yodf9MjUdRfMg1iM8', "_blank")}
            onClick={() => scroll('photos')}
          >
            Photos
          </div>
        </div>
      </div>
      <div className={styles.headerRowBlock} />

      <div className={styles.coverPhotoRow}>
        <div className={styles.coverImages}>
          <img alt='' className={styles.coverPhotoImg} src={`${process.env.PUBLIC_URL}/coral 1.png`} />
          <img alt='' className={styles.coverPhotoImg} src={`${process.env.PUBLIC_URL}/coral 4.png`} />
          <img alt='' className={styles.coverPhotoImgShell} src={`${process.env.PUBLIC_URL}/shell 2.png`} />
        </div>
      </div>

      <div id='events' className={styles.eventsSection}>
        <div className={styles.eventsInfo}>
          <div className={styles.eventsTitle}>
            BRETT & JULIA'S WEDDING CELEBRATION
          </div>
          <div className={styles.eventsList}>
            <div className={styles.event}>
              <div className={styles.eventTitle}>Welcome BBQ</div>
              <div className={styles.eventDateTimeContainer}>
                <div className={styles.eventDate}>FRIDAY, OCTOBER 29</div>
                <div className={styles.eventTime}>5:30 PM Cocktails and Putting Contest</div>
                <div className={styles.eventTime}>7:00 PM Buffet and Music</div>
              </div>
              <div className={styles.eventAddress}>
                <div>EAGLE POINT GOLF CLUB</div>
                <div>8131 BALD EAGLE LANE </div>
                <div>WILMINGTON, NORTH CAROLINA</div>
              </div>
              <div className={styles.eventHostContainer}>
                <div className={styles.eventHost}>Hosted by</div>
                <div className={styles.eventHost}>The Hawkins and Leibowitz Families</div>
              </div>
              <div className={styles.eventAttire}>Country Club Casual Attire</div>
              <div className={styles.eventAttire}>No jeans</div>
              <div className={styles.eventTransport}>Transportation provided from & to the Blockade Runner hotel</div>
            </div>
            <div className={styles.event}>
              <div className={styles.eventTitle}>Wedding Celebration</div>
              <div className={styles.eventDate}>SATURDAY, OCTOBER 30</div>
              <div className={styles.eventTime}>5:30 PM</div>
              <div className={styles.eventAddress}>
                <div>CAROLINA YACHT CLUB</div>
                <div>401 S LUMINA AVE</div>
                <div>WRIGHTSVILLE BEACH, NORTH CAROLINA</div>
              </div>
            </div>
            <div className={styles.event}>
              <div className={styles.eventTitle}>Continental Breakfast</div>
              <div className={styles.eventDate}>SUNDAY, OCTOBER 31</div>
              <div className={styles.eventTime}>8:30-10:30 AM</div>
              <div className={styles.eventAddress}>
                <div>HAWKINS BEACH HOUSE</div>
                <div>203B S Lumina Ave.</div>
                <div>WRIGHTSVILLE BEACH, NORTH CAROLINA</div>
              </div>
              <div className={styles.eventTime}>Casual Attire</div>
            </div>
              <div className={styles.sweaters}>Note: All events have outdoor areas and can get cool after sunset. Sweaters and jackets are encouraged!</div>

          </div>
        </div>
      </div>

      <div className={styles.weatherWrapper}>
        <div>
          <ReactWeather
            isLoading={isLoading}
            errorMessage={errorMessage}
            data={data}
            lang="en"
            locationLabel="Wrightsville Beach"
            unitsLabels={{ temperature: 'F', windSpeed: 'M/h' }}
            showForecast
          />
        </div>
      </div>

      <div id='lodging' className={styles.whereSection}>
        <div className={styles.whereTitle}>
          Lodging
        </div>
        <div className={styles.whereTextContainer}>
          <div className={styles.whereText}>
            Please mention the Hawkins-Leibowitz wedding when making reservations.
          </div>
        </div>
        <div className={styles.whereAddress}>
          <div>BLOCKADE RUNNER</div>
          <div>275 Waynick Blvd</div>
          <div>WRIGHTSVILLE BEACH, NORTH CAROLINA</div>
        </div>
      </div>

      <div id='travel' className={styles.travelSection}>
        <div className={styles.travelTitle}>
          Travel
        </div>

        <div className={styles.travelTextContainer}>
          <div className={styles.travelText}>
            Wrightsville Beach is a long beach island on the Southeast coast of North Carolina. Once you arrive, you will not need a car as everything will be walking distance from lodging. There are two travel options for those coming from out of town.
          </div>

          <div className={styles.travelOption}>
            <div className={styles.travelOptionTitle}>
              Option 1:
            </div>
            <div className={styles.travelOptionText}>
              Fly directly into Wilmington International Airport (ILM) and take a ride share service to Wrightsville Beach 20 minutes away.
            </div>
          </div>
          <div className={styles.travelOption}>
            <div className={styles.travelOptionTitle}>
              Option 2:
            </div>
            <div className={styles.travelOptionText}>
              Fly into Raleigh-Durham International Airport (RDU) and drive to Wrightsville Beach for about two and a half hours. It is an easy drive with only four turns.
            </div>
          </div>
          <div className={styles.travelOption}>
            <div className={styles.travelOptionTitle}>
              Covid:
            </div>
            <div className={styles.travelOptionText}>
             We know that you will decide to stay in your hotel room if you are not feeling well. If that is the case, please let us know and we will attempt to bring you food from the event.  
            </div>
          </div>

        </div>
      </div>

      <div id='registry' className={styles.registrySection}>
        <div className={styles.registryTitle}>
          Registry
        </div>
        <div className={styles.registryTextContainer}>
          <div className={styles.registryText}>
          <span className={styles.heart}>&#x2665;</span>
            We are honored that so many of you care enough about us to consider coming to Wrighsville Beach in October. We are not expecting anything from you, but if you'd like to pass along a gift, here are some places we have registered. Much love & appreciation!
            <span className={styles.heart}>&#x2665;</span>
          </div>
        </div>
        <div className={styles.registries}>
          <div className={styles.registryName}>
            <a className={styles.registryNameLink} href='http://www.zola.com/registry/brettandjulia2020' target='_blank' rel="noopener noreferrer">
                <img alt='' src='https://d1tntvpcrzvon2.cloudfront.net/vnassets/static/media/zola-logo-horizontal-black.34453bcb.svg' className={styles.registryImageSwans}
                />
                <b  className={styles.registryCopy}>Zola</b>
              </a>
          </div>
        </div>
      </div>

      <div id='photos' className={styles.photoSection}>
        <div className={styles.photoTitle}>
          Photos
        </div>
        <Gallery photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen && (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </Modal>
          )}
        </ModalGateway>
      </div>

      <div id='weddingPhotos' className={styles.photoSection}>
        <div className={styles.photoTitle}>
          Wedding Photos
        </div>

        <div className={styles.albumWrapper}>
          <div className={styles.albumName}>
            <a className={styles.albumNameLink} href='https://photos.app.goo.gl/ZVCLQ4HH1Ba18sHt6' target='_blank' rel="noopener noreferrer">
              <img alt='' src='https://lh3.googleusercontent.com/a886DCvhHki1Ak5xRM8cEEO88dpLX_IHh9Cb1K_TqjXEMdRdUdF_hVf7JwEzCCDf6_y4Ci73HKEix6h-x_7z3WNj_HL7_2YCAVOJ8HapworXIXdVVXKnl7iqy2vUTObUzCj8sn239U_XVtA5l5a4Gde8prkZbFleditrk0uobF2RbB3NV3Hsa1KGQg0lct09UZ09yvuzETGFqAo3WMU_OzLbvg-CJxNkMXepjVFKepn6pWk2ZgVEgO_p_O9Ncl5cVQzcfb5LOSQX2-_2Sh8dfxw7x4i3HEKVFADmhfTPV5bEIl9Ffts8_U41UeaU4NJSXZ2B433jCwGFF9Nne4xUJTZz3hZy2cqYuOuz_bx01k_OhSX1EpfN6m3cAPHGd7-5C1PauE_r5kLm1coj72slElgMHQ6BEFDcodVQrAn_7A0AXobOUUALFVpZL_o_94HZziLkwYx5pyZ3VTZmW9QouYQVmBmrZpTKDnIxbjr-mUjB7-0Hha03cIzXPDPfnWfQ8sbZSS21ByRd2SHpunGuJIjy89ag8WK0tArfGuTE58z_S5Zt9JsFWGz1Egdtr5HaNU5aotoX7AijAY2vYr7z_fSW1iq9DGIwriVdTUgSuW-wgFw5sJCQ1NyNFoy1_SrIPtID7y-cZEvawEm0uHhufM5oQtF3zLye941p9UARv-QJHao6lFzOWKov0GCYeLQ5_b6JACXK28b8zQcRmYKGA5VnxELoOazsRp74pMk4dOl7jO49ofux5WoANAzt0al8MMibgxIzu99cvAiWtVwHVji-EQBw3QUlyA=s128-p-no' className={styles.albumImage}
              />
              <b  className={styles.albumCopy}>Getting Ready</b>
            </a>
          </div>

          <div className={styles.albumName}>
            <a className={styles.albumNameLink} href='https://photos.app.goo.gl/EhN9osFrCsPxXjdPA' target='_blank' rel="noopener noreferrer">
              <img alt='' src='https://lh3.googleusercontent.com/nHqkAUW53bbga3vRTrG9eF0882RWHxEVIs49QMojKSIBEG7r194aCbsolDeVn-Ldp6y64YVJT6OxvliNIILGYnsxGsxUqMXHEloetPoz932eBJ7mebxzWJ5x45sAPtuLJhcjOM1lSTHY_sL0uROve-k6olIygc7HxM_gU7fpZAQzwI-6gPu_bEy1DnlyobqW--v2GmKzFagQrCsHkhFKIAirdJWJQgg51RiBTTDaiLYuchP23PqQO-HxVDGZUFnsQ97mPDHOvPgmi2AegAVuCtDRRhVgZNTD5S1zKhdEFaKadYGQAoFh6Ag_jo0uWYOjM_tR2UQxgpowPAA3JEs1f0piAukGAtCHK2DbkUkQfQ7wwsejO7XNvkeEk-1oBDxOfrO9ZdlFIePuwPnNBh0ww0EvlX4eihjdVDFC9LcuUMbTCTsY5jX9Tk3vw7wb6_8unal-z2a-577lkCf5AkA0bK7Ex8N9fV2UAvQj2lVVzXxU1RS3DtQGuU32zElPlKoCocGN1dSZabn6TnSCkcPc-XSJhzg4DJ7oWHSkhndShnjrQu3xjb8Cn8A56tSIuqgho2tk31cLodMzZeZv3ExQGyMTvYTUJEXJIdNCqVWIKpWcEABWFca_kW6Q6VOxk0_Nf2XKcuNHP1bPsEeiwybVYNVmZuWZ_0NscG4_hnGht2xbKnHiJSfQmxIvwG5w9dFXlA8YRHuZ1sEs38iegrWEuH5a50OkbFbNg2k1rwaI2yzYP1ubTQU2AOsxPngz5Oo2tJSWwfj8rrT-NV0955pRioPc6K8Uxdvb7A=s128-p-no' className={styles.albumImage}
              />
              <b  className={styles.albumCopy}>Portraits</b>
            </a>
          </div>

          <div className={styles.albumName}>
            <a className={styles.albumNameLink} href='https://photos.app.goo.gl/yobLXiyZ7qEF9BuU8' target='_blank' rel="noopener noreferrer">
              <img alt='' src='https://lh3.googleusercontent.com/JSGfqZbXhOFGv6jWclM85QAe-WYG2sZu-ATHUcEv6ns2fVRwjSrYZWmHBH2WlXFIMhHjgKgAvjqeNy281dJgjI7rQwQirM0rcRTW__ocY1aHt1IP1yuBt5PjyVfhBTUk6RX6D4GOKOkcsOSCCoS6a8scLxHI4q_-_PGZ6dqoFdwd6UH237E6yHjrACxhJrmLNejeYblr-nCJnBy0-vPpIINRJugYyM935LLRacp-TehXp60ZluuCLS6PZd1-NSYKn1zD7vmcxH4FQlwRORQklu1DLak7HvOp_ZDFI78hmrwSB3JrJftE5bVxSQJEgXty_9IkRzG0zokkJHeF7dniI078TyWrpMufWPyC20a1MYbyZ8hjirhOdSnMEU07rYeZTluqpRWWTMWYDDsZgmj6Mmm5tx7ECp11Zv-iPokT6BxzhZMO5IGc_PSJueII8Xn969Tzk1SqRSbEwDcz9D_sSIYhSDGXtos9A299To5xIFRbhkpw75athiGUWf7YbHmzJKieAfu_mPZMFOMjIpX8rBOHFzjTCLJTMYGKMNn0L6t9OYBVjV6w0z4eeRGA21nZNGQiUBgOE7pw-WpBdom06vfWbifXBuPzo4dWZpgBpYWA0CdN6qWDXQl7ei7vfbDQoL9UHtoTU26xj8cgUC3_0db9CFDSzPOP-KS-zrzzl5IKozJdLuRob6Hn_UfdXPnO7ht0zKBuvCgb7e8UvwoWA3n5aIIA0xanMgWrrE2PB9_tmu4Ytf2_tCuPxL1bKhpDzx51MjoeTIPfmjvOFf12aJsDGnX3hi5roA=s128-p-no' className={styles.albumImage}
              />
              <b  className={styles.albumCopy}>Welcome Party</b>
            </a>
          </div>

          <div className={styles.albumName}>
            <a className={styles.albumNameLink} href='https://photos.app.goo.gl/YmBXgH5ug5mo6JNk6' target='_blank' rel="noopener noreferrer">
              <img alt='' src='https://lh3.googleusercontent.com/QOuM3pADkPnYEFwQ7SieRMq9kluNgejkXR-l-wve7_w7xJbgTBIVS_ZV4XRH3LiS9NcDmpoSOiWu71j5BV2u3Zn8hoLc_hftWgRfM3ETW3igBhofXNFK099CuF0pM30gM0Ew5NhtIhqM5WVH3zBrJDwQeUj_NtKKxN7XBYE9rUxFvqXtprPag0VXXFWTWZBnQUTPYQx2YFVSKh4OwV-b1vcJ_v0gPOWmsi-TKro8nrIjWkT-YXn3XXbQAZJWCcbAwDEew-2dXr0zRiHvmcSrKmrr_bLGS2uTXSP0BZK3AA0xO-Ys40c2U7ml6ZNasD-b778B4hCnzMaQIs8lPo-U02_Tlxsg_6yykGzFKXa7vA87Tfu8FeRtoCCTDMaXhwGfUNoFGPqUot1ztCzj_AqTKgzXp30iYJ-i5o_0X9m0uyob51mJZdHo8r7kUSBw7ixjx1aXkLqARcOyagUuVEQOMzjJCpPjckVolXVyUgG0gGP8bYHIpPcDBV83JoyfpfND03zXCYDEyzuQLnWVmuND5lgotmkipzxiYzSIq63S7cWtKlOxR-_-27PgScaPruusYVOIlgAEZf-wfVUBL6CpGsCGxkQHUm3J0yZNxXF1AT6ElMh16Zz36sq3-hoyTAScEcRGh6j6itUcQdDrp5pPS32sbp-bxV76TXaMYgngpHCLH2SwMH-ZvdNsYtd6-u2cx0nynYdLe9WouIFzNk0Cmy68IusNqMq0Z7nyNOwaLnK54sWCfSFl835ft70sZ4wns-v-xdFphiUYQ64bur3_fmijVRNw1Q07UA=s128-p-no' className={styles.albumImage}
              />
              <b  className={styles.albumCopy}>Cocktail Hour</b>
            </a>
          </div>

          <div className={styles.albumName}>
            <a className={styles.albumNameLink} href='https://photos.app.goo.gl/7MexFiVuzwuKiBvN6' target='_blank' rel="noopener noreferrer">
              <img alt='' src='https://lh3.googleusercontent.com/PZ6Zk6J78NWRvylFYQyjH7j5Xx3T0tC2fEpPQujZmf2Vwr5c5ar9yjx0B75iYUfL3whUdnNrWDZgI3YhdsEsJYoun6Qgl_hEwk-uqHSiHAZLBpW2XlYQS7VbkeHFdsWox6zlU8NLG7l-ObSAcU-cx7CZGEBmcVyGQ-GfYUdUt58Uvc6dCsFHDg_FSRevE0a-24CWTRoPLZcd9sTMU5_kCmIn0vJKX5MoajC-3IC9_vI5Ryz5zdAZ0ZGk5FslKrQi2Y3MLbxMgKpBq9L5MbN1B0UjNT8jsF47oxVyasVH9FyyBo4oeHYoICdPUuRq-Lca1kd6QGgJw5F_dwLVNT54pgTzyzgrk1IEIOPQPDQSu83NDD3GSF9rxX7fIGyt1xJYInW_tzuYB7qgEKwRScX73LBaWnUDl6MBfr6EFzODmUKrklv_QV-IztLDLWL5_wNbZV3rx4zqr9Cd-OzJpD9CY0no69q_UHSmxS9wnXVraufTtfxqNaX4m2WTbPu90hfZA8Mm8jy-8SI5YBoKAbHHpKQcd_duhZNSfyN_ap6oheR0myxrTyhF-RuLaRoA3GsF5F8MQbi5WlB2RxxrZ_OPQbBFgNUdpcK18F24pZC-rG51fwEwcBVOcjf1M0Uh-VzAnG705wl-RxQckqbNkUw6doS04dbvum_FvtTWEjd9bgHiNbmwTFdkgH6y7iFut3GVIOO9Re8uOXy8U_1LrnyrwSwz77Sb7h8JYHR9hxURZ2l2BdJgNb20MdOXJGyoY_sjsqyl2GT5JoVTWsuZ3DYrNTVi5CXDuoJDNw=s128-p-no' className={styles.albumImage}
              />
              <b  className={styles.albumCopy}>Wedding Video</b>
            </a>
          </div>

          <div className={styles.albumName}>
            <a className={styles.albumNameLink} href='https://photos.app.goo.gl/d6E759ECVyWHDJcn6' target='_blank' rel="noopener noreferrer">
              <img alt='' src='https://lh3.googleusercontent.com/Sr7SSgQQZo5wlLh5AI6WQXxOQI2DxEbHJ1ujfNw_g0bgIPJQXy6nrz0eDCF8gd5-fBj7r0OvDm04iJYsdkPZE2CAKkogMeZPojFgsDmzG9C4QVv9MubwV39PJWqU94l2tKX5S5J2FgP0drJL9MT0Z1UioB3l8M_GxswjbbHxDhpBe5t-S4LudOWiT6Z83qsNpyX1FPI8MQC4zZqYJNoN13403IXsXVyxg7WMP3ddGsXmR1YWIOH08yNOQjzcdHBwFhBLvQLPOdswZClu1KAUREp5GnrPtm3wVcD7NuRRx8APigu4Lj9pPyUpyw9ZbvQ67YbQjXzjbPNWIuhg5zUl4ZPKREKHUZG3-yXY6NpKnjAvJsUUCmlHuj2boqwiXm1v8hCWs2Bm8xYTVMZYlLINgVIH5Yt3D0WeRtUFxPl6EbZ0ctqs0HQbMujYTnX5IHaPMJ44kR8aXbfR7SCKLeirqT1QwEua4iYcWFAWd9JDkTyK9jbodTPop3iW9_UzymLBVsadQkmz2eB29azRQnwmP_3hirPPecF5mDuoHRp3yUj2kiO2F6FeZJzexxtiNk2CUdCoNf7Yk-vV6sU8NEcKm2CH9BlJ_a59W3IckGRO9lGyQodto7Q844rQf08ZFM5EGrhLQzqlh1NuDy-IjKmzC6f1TN1dXATb23WeVX5Pw8T6p1BcNtN-QQaxdBVW6mOQAriQD9gAuF-d3-jf7ViXsg5KylW1FDRH3jVOMAD1iRAKNlz581nPnJumMTy4BLIb7JogCAaxKBLfPB-9Y49vMKJEOy9ITtkCWQ=s128-p-no' className={styles.albumImage}
              />
              <b  className={styles.albumCopy}>Dinner Reception</b>
            </a>
          </div>

          <div className={styles.albumName}>
            <a className={styles.albumNameLink} href='https://photos.app.goo.gl/wuMNejrypCZGdmvA6' target='_blank' rel="noopener noreferrer">
              <img alt='' src='https://lh3.googleusercontent.com/cOPFClAJTvtoYn22BG3xXwjd0zeVuLtH_JbOSk5UoKV2Q9ucnJdIhts8xM9tRkitUgofdWbCGjWkiEMTAbuPveR5UFZ7EQhvOVy040CSiwn6DsEKncqrZBwAG0ycCsr-6mzRd8Ps5g9UiVbozI8KQoou8Z5GNE6v12RyxR7wWa6J21pPS_rG5M0mctko_8RF4baVVsPp1mVsAP_a7V8PV89EvIqCsUTBCxnWU_gsFRuA1BvjnmQACBFOfORqyZdK4uTSsIEcPd-bsZoiBuz6vwGJSvFFfrZBcRV2DSt3Oq-FLYh_pBBnGczvWEiCysKUrBoBm6WIaIWZ6-dDZt4QmXIVswsCXAPzZr29uEiHl0rhpWW70izEboFwbc_UqZyChd8jvo_utodDpmuUBVu91Clpj-kNiwckYk-D0WORIWgKVGAC6uE7Jwp5HazDiKUL21XOQoCItePeWkapC2LD1P2mYWpjz11YVhnawmk4pfVST0jpsxQn-gp6dQp9zSq5qP31Y94A1kMlsL0SPySx7iemYMC-UtO8hYXpEtlG46BA4QfeE5HsQ59azpvBbQJ2UJD_t-Ma88v2FlmYXWrBXwOcuAAyOXVyAAAYU6mwphF19XtmuM3PrgmWw8qE6V9OtbgSXohnv2cVmy_Sqm6ld-mJzXfEym7a-rW6tsWx9CW4f-Om3aVj94ZZQsWgyS6jphgFGvLIEnEPSj6L5uVEELy3w-l82LCLTBNH_vJooOC7YdGzf3fqlKJzcaGdC5bg1o7eAXBweJu0v4aIPgnTTSrIpoxsgku7lA=s128-p-no' className={styles.albumImage}
              />
              <b  className={styles.albumCopy}>Dancing</b>
            </a>
          </div>
        </div>
      </div>

    </div>
  );
}

export default App;
